<script setup>
import Button from '@/Components/Button/Button.vue';
import Footer from '@/Components/Footer/FooterLogin.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm, router } from '@inertiajs/vue3';
import { inject, onMounted, ref } from 'vue';

let route = inject('route');

const timeLeft = ref(30);
const renderTime = () => {
    if (timeLeft.value == 0) {
        clearInterval(timerInterval);
    } else {
        timeLeft.value--;
    }
};
const timerInterval = setInterval(renderTime, 1000);

const recovery = ref(false);

const form = useForm({
    code: '',
});

const resend = () => {
    router.get(route('two-factor-phone.login'));
};

const submit = () => {
    form.transform((data) => ({
        ...data,
    })).post(route('two-factor-phone.login'), {
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

onMounted(() => {
    renderTime();
});
</script>

<template>
    <GuestLayout title="Two-factor Confirmation">
        <div class="container flex flex-col items-center w-full relative -top-[200px]">
            <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
                <h1 class="text-[32px] font-bold mb-2">{{ $t('Verify your phone number') }}</h1>
                <p class="mb-5 text-base">
                    {{
                        $t(
                            'Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.'
                        )
                    }}
                </p>

                <form @submit.prevent="submit">
                    <div v-if="!recovery">
                        <FormKit
                            type="text"
                            :label="$t('6-Digit code')"
                            v-model="form.code"
                            :errors="form.errors.code"
                            :value="form.code"
                        />
                    </div>

                    <div v-else>
                        <FormKit
                            type="text"
                            :label="$t('6-Digit code')"
                            v-model="form.recovery_code"
                            :errors="form.errors.recovery_code"
                            :value="form.recovery_code"
                        />
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <Button
                            type="button"
                            @click="resend"
                            :processing="form.processing"
                            :class="{ 'opacity-50 pointer-events-none': timeLeft !== 0 }"
                        >
                            <span v-if="timeLeft !== 0">
                                {{ $t('Resend in') }}
                                {{ timeLeft }}
                                {{ $t('sec') }}
                            </span>
                            <span v-else>{{ $t('Resend') }}</span>
                        </Button>
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <Button full-width :processing="form.processing">{{ $t('Next') }}</Button>
                    </div>
                </form>
            </div>

            <Footer />
        </div>
    </GuestLayout>
</template>
